import React, { createContext, useContext } from 'react';

const AudioContext = createContext();

export function AudioProvider({ children }) {
  const sounds = {
    GBeep: new Audio(process.env.PUBLIC_URL + '/sounds/GBeep.mp3'),
    ABeep: new Audio(process.env.PUBLIC_URL + '/sounds/ABeep.mp3'),
    CBeep: new Audio(process.env.PUBLIC_URL + '/sounds/CBeep.mp3'),
    EBeep: new Audio(process.env.PUBLIC_URL + '/sounds/EBeep.mp3'),
    DBeep: new Audio(process.env.PUBLIC_URL + '/sounds/DBeep.mp3'),
    buzzer: new Audio(process.env.PUBLIC_URL + '/sounds/buzzer.mp3'),
  };

  const playSound = (soundName) => {
    if (sounds[soundName]) {
      const sound = sounds[soundName];
      sound.currentTime = 0;
      
      // Create and play a new instance to allow overlapping sounds
      const newSound = new Audio(sound.src);
      newSound.play().catch(error => {
        console.log("Audio play failed:", error);
      });
    }
  };

  return (
    <AudioContext.Provider value={{ playSound }}>
      {children}
    </AudioContext.Provider>
  );
}

export function useAudio() {
  return useContext(AudioContext);
}

