import logo from './logo.svg';
import './App.css';
import Screens from './Screens';
import { AudioProvider } from './AudioContext';
import TVTest from './TVTest';


function App() {

  

  return (
    <AudioProvider>
      <TVTest />
    </AudioProvider>
  );
}

export default App;
